.edit-header {
    margin: 0 auto;
}



.edit-body {
    marging: 0 auto;
}





.edit-item {
    margin: 0 auto;
    text-align: center;
  
}
